import React, { RefObject } from "react";
import { Automation, AutomationAction, AutomationCondition, Device } from "../service/API";
import { Store } from "../service/Store";
import { inject } from "mobx-react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonTextarea,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { add, checkmarkOutline, closeOutline, trash } from "ionicons/icons";
import { Action } from "../service/Operators";
import { ConditionConnector, ConditionsValues, Operand } from "../service/ConditionsValues";
import Utils from "../service/Utils";
import EventBox from "../service/EventBox";
import Lang from "../service/Lang";

export type DeviceAutomationProp = {
    device: Device,
    addAutomation?: boolean,
    store?: Store,
    lang?: Lang,

    routerOutlet: HTMLIonRouterOutletElement,
}

export type DeviceAutomationState = {
    automationList: Automation[],
    edit: boolean,
    editCond: boolean,
    conditionIndex: number,
    condition: AutomationCondition,
    loading: boolean,
    editAction: boolean,
    action: AutomationAction,
    actionIndex: number,
    automation: Automation,
}
@inject("store", "lang")
export default class DeviceAutomation extends React.Component<DeviceAutomationProp, DeviceAutomationState> {
    private events?: EventBox;

    values?: ConditionsValues;
    operationEditor: RefObject<HTMLIonInputElement> = React.createRef();
    automationModal: RefObject<HTMLIonModalElement> = React.createRef();
    conditionsModal: RefObject<HTMLIonModalElement> = React.createRef();
    actionsModal: RefObject<HTMLIonModalElement> = React.createRef();

    state = {
        automationList: [],
        edit: false,
        editCond: false,
        conditionIndex: 0,
        loading: false,
        editAction: false,
        actionIndex: 0,
        automation: this.createAutomation(),
        condition: this.createCondition(),
        action: this.createAction(),

    } as DeviceAutomationState;

    componentDidMount(): void {
        this.loadAutomation();
        this.values = new ConditionsValues(this.props.device);
        this.events = new EventBox(this.props.store?.events!);
        this.events.on('automation', () => this.loadAutomation());
    }

    componentWillUnmount(): void {
        this.events?.off();
    }

    componentDidUpdate(prevProps: Readonly<DeviceAutomationProp>, prevState: Readonly<DeviceAutomationState>, snapshot?: any) {
        if (prevProps.device !== this.props.device) {
            this.loadAutomation();
        }
    }

    createAutomation(): Automation {
        return { id: 0, device_id: this.props.device.id, description: '', enabled: true, mode: 'and', title: '', trigger_delay: 60, trigger_rearm: 0, trigger_time: 0, triggered: false, conditions: [], actions: [], exec: 'update', exec_last: false };
    }

    addAutomation() {
        this.editAutomation(this.createAutomation());
    }

    editAutomation(automation: Automation) {
        this.setState({ edit: true, automation: automation });
    }

    async loadAutomation() {
        this.values = new ConditionsValues(this.props.device);
        let value = await this.props.store?.api.automation(this.props.device.id);
        if (value && value.success) {
            this.setState({ automationList: value!.data });
        }
        return value;
    }

    createCondition() {
        return { connector: ConditionConnector.AND, left: '', op: '', right: '', tokens: [] };
    }
    addCondition() {
        this.setState({ editCond: true, conditionIndex: -1, condition: this.createCondition() });
    }

    editCondition(index: number) {
        if (!this.state.automation) return;
        this.setState({ editCond: true, conditionIndex: index, condition: this.state.automation.conditions[index] });
    }

    createAction() {
        return { action: 'none' };
    }

    addAction() {
        this.setState({ editAction: true, actionIndex: -1, action: this.createAction() });
    }
    editAction(index: number) {
        if (!this.state.automation) return;
        this.setState({ editAction: true, actionIndex: index, action: this.state.automation.actions[index] });
    }

    // parseConditions(condition:string) {
    //     let op = new Operators();
    //     return op.fromJSON(condition);
    // }
    //
    // buildConditions(condition:AutomationCondition[]) {
    //     let op = new Operators();
    //     return op.toJSON(condition);
    // }


    parseActions(actions: string): Action[] {
        try {
            return JSON.parse(actions);
        } catch (e) {
            return [];
        }
    }

    buildActions(actions: Action[]): string {
        return JSON.stringify(actions);
    }

    handleCheckChange = (key: string) => (ev: any) => {
        this.setState({ automation: { ...this.state.automation!, [key]: ev.detail.checked } })
    };
    handleChange = (key: string) => (ev: any) => {
        this.setState({ automation: { ...this.state.automation!, [key]: ev.detail.value } })
    };

    handleEditAutomation = async (ev: any) => {

        this.setState({ loading: true });
        let result = await this.props.store?.api.automationAdd(this.state.automation!);
        this.setState({ loading: false });

        if (result && result.success) {
            this.loadAutomation().then(() => {
                this.setState({ edit: false });
            });
        }
    };

    handleEditCondition = (ev: any) => {
        let conds = this.state.automation!.conditions;
        let cond: AutomationCondition = this.state.condition!;
        if (this.state.conditionIndex === -1) {
            conds.push(cond);
        } else {
            conds[this.state.conditionIndex] = cond;
        }
        this.setState({ editCond: false, automation: { ...this.state.automation!, conditions: (conds) } });
    }

    handleRemoveCondition = (ev: any) => {

        if (this.state.conditionIndex >= 0) {
            let conds = this.state.automation!.conditions;
            conds.splice(this.state.conditionIndex, 1);

            this.setState({ automation: { ...this.state.automation!, conditions: conds } })
        }
        this.setState({ editCond: false, conditionIndex: -1 });
    };

    handleEditAction = (ev: any) => {
        let actions = this.state.automation.actions || [];
        let action = this.state.action!;
        if (this.state.actionIndex === -1) {
            actions.push(action);
        } else {
            actions[this.state.actionIndex] = action;
        }
        this.setState({ editAction: false, automation: { ...this.state.automation, actions: actions } });
    };

    handleRemoveAction = (ev: any) => {
        if (this.state.actionIndex >= 0) {
            let actions = this.state.automation.actions;
            actions.splice(this.state.actionIndex, 1);
            this.setState({ automation: { ...this.state.automation, actions: actions } })

        }
        this.setState({ editAction: false, actionIndex: -1 });
    }

    handleRemoveAutomation = (ev: any) => {
        const { lang } = this.props;
        if (this.state.automation.id > 0) {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(lang?.l.main.automation_confirm())) {
                this.setState({ loading: true });
                this.props.store?.api.removeAutomation(this.props.device.id, this.state.automation.id).then(value => {
                    this.setState({ loading: false, edit: false });
                    this.loadAutomation();
                })
            }
        }
    }

    handleReplaceVariable = (name: string) => (ev: any) => {

        let suggestion = this.state.condition!.left;
        if (this.operationEditor.current) {
            let input = this.operationEditor.current.querySelector("input");
            if (input) {
                let s = input.selectionStart;
                if (s !== null && s >= 0) {

                    suggestion = this.applyMatch(suggestion, s, name);
                    this.setState({ condition: { ...this.state.condition!, left: suggestion } })
                }
            }
        }

    }

    applyMatch = function (suggestion: string, s: number, name: string) {
        let i1 = suggestion.indexOf(" ", s);
        let word = suggestion;
        if (i1 >= 0) {
            word = suggestion.substr(0, i1);
        }
        i1 = word.lastIndexOf(" ");
        if (i1 > 0) {
            word = word.substr(i1 + 1);
        }
        if (i1 >= 0) {
            suggestion = suggestion.substr(0, i1 + 1) + name + suggestion.substr(i1 + word.length + 1)
        } else {
            suggestion = name + suggestion.substr(i1 + word.length + 1)
        }
        return suggestion;
    }

    evaluateExpresion(expresion: string) {

        return 0;
    }

    renderActionOutput(action: AutomationAction) {
        const { lang } = this.props;
        let outputs = this.props.device.outputs;
        let output = Utils.getOutputByIndex(outputs, parseInt(action.output_index! + ""));
        return <>
            <IonItem>
                <IonSelect labelPlacement="floating" label={lang?.l.main.atomation_output_name()} interface={"action-sheet"} value={action.output_index} onIonChange={(ev: any) => { action.output_index = ev.detail.value; this.setState({}) }}>
                    {outputs.map((value, index) => <IonSelectOption key={index} value={value.oindex + ""}>{value.name}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
            {output && output.type === "toggle" && <IonItem>
                <IonSelect labelPlacement="floating" label={lang?.l.main.automation_change_state()} interface={"popover"} value={action.output_state} onIonChange={(ev: any) => { action.output_state = ev.detail.value; this.setState({}) }}>
                    <IonSelectOption value={"1"}>{lang?.l.main.automation_on()}</IonSelectOption>
                    <IonSelectOption value={"0"}>{lang?.l.main.automation_off()}</IonSelectOption>
                </IonSelect>
            </IonItem>}
            {output && output.type === "value" && <IonItem>
                <IonInput labelPlacement="floating" label={lang?.l.main.automation_set_value()} onIonInput={(ev: any) => { action.output_state = ev.detail.value; this.setState({}); }} value={action.output_state}></IonInput>

            </IonItem>}
            {output && output.type === "select" && <IonItem>
                <IonSelect label={lang?.l.main.automation_select_value()} labelPlacement="floating" interface={"popover"} value={action.output_state} onIonChange={(ev: any) => { action.output_state = ev.detail.value; this.setState({}) }}>
                    {output.values?.map((value, index) => <IonSelectOption key={index} value={value.value}>{value.title}</IonSelectOption>)}
                </IonSelect>
            </IonItem>}

        </>
    }

    renderActionEmail(action: AutomationAction) {

        const { lang } = this.props;
        return <>
            <IonItem>
                <IonInput labelPlacement="floating" label={lang?.l.main.automation_destination_email()} onIonInput={(ev: any) => { action.email_email = ev.detail.value; this.setState({}); }} value={action.email_email}></IonInput>
            </IonItem>
            <IonItem>
                <IonTextarea labelPlacement="floating" label={lang?.l.main.automation_email_message()} onIonChange={(ev: any) => { action.email_body = ev.detail.value; this.setState({}); }} value={action.email_body} />
            </IonItem>
        </>

    }

    renderActionInput(action: AutomationAction) {

        // const { lang } = this.props;
        let inputs = this.props.device.inputGroups;
        return <>
            <IonItem>
                <IonSelect labelPlacement="floating" label="Nombre de Entrada" interface={"action-sheet"} value={action.input_id} onIonChange={(ev: any) => { action.input_id = ev.detail.value; this.setState({}) }}>
                    {inputs.map((value, index) => value.inputs.map((value2, index2) => <IonSelectOption key={index2} value={value2.id + ""}>{value.name} / {value2.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonTextarea label="Valor (Puede ser una formula)" labelPlacement="floating" onIonChange={(ev: any) => { action.input_value = ev.detail.value; this.setState({}); }} value={action.input_value} />
            </IonItem>
        </>

    }
    renderActionNotification(action: AutomationAction) {

        // const { lang } = this.props;
        // let inputs = this.props.device.inputGroups;
        return <>
            <IonItem>
                <IonTextarea label="Mensaje Notificación" labelPlacement="floating" onIonChange={(ev: any) => { action.notification = ev.detail.value; this.setState({}); }} value={action.notification} />
            </IonItem>
        </>

    }
    renderActionWait(action: AutomationAction) {

        // const { lang } = this.props;
        // let inputs = this.props.device.inputGroups;
        return <>
            <IonItem>
                <IonInput labelPlacement="floating" label="Tiempo en Segundos" onIonInput={(ev: any) => { action.wait_secs = ev.detail.value; this.setState({}); }} value={action.wait_secs}></IonInput>
            </IonItem>

        </>

    }

    renderDisplayActionOutput(action: AutomationAction) {
        let outputs = this.props.device.outputs;
        let output = Utils.getOutputByIndex(outputs, parseInt(action.output_index!));
        let state = Utils.toBoolean(action.output_state!);
        if (!output) return "";

        return <>
            Establecer <IonBadge color={"secondary"}>{output.name}</IonBadge> a <IonBadge color={state ? "success" : "danger"}>{state ? "ON" : "OFF"}</IonBadge>
        </>
    }



    renderDisplayActionEmail(action: AutomationAction) {
        const { lang } = this.props;

        return <>
            {lang?.l.main.automation_send_email_to({ email: action.email_email })}
        </>
    }

    renderDisplayActionNotify(action: AutomationAction) {
        const { lang } = this.props;

        return <>
            {lang?.l.main.automation_show_notification()}
        </>
    }

    renderDisplayActionInput(action: AutomationAction) {
        // const { lang } = this.props;

        let input = Utils.getSubInputById(this.props.device.inputGroups, parseInt(action.input_id!));

        return <>
            Establecer Valor a {input ? input.name : "-"}
        </>
    }
    renderDisplayActionWait(action: AutomationAction) {
        // const { lang } = this.props;

        return <>
            Esperar  {Utils.toTimeDuration(parseInt(action.wait_secs!))}
        </>
    }

    renderActionEditor() {
        const { lang } = this.props;
        if (!this.state.action) return "";
        return <>

            <IonItem>
                <IonSelect label={lang?.l.main.automation_action_type()} labelPlacement="floating" interface={"action-sheet"} value={this.state.action.action} onIonChange={(ev: any) => this.setState({ action: { ...this.state.action, action: ev.detail.value } })}>
                    <IonSelectOption value={"none"}>(Seleccionar)</IonSelectOption>
                    <IonSelectOption value={"output"}>{lang?.l.main.automation_set_output()}</IonSelectOption>
                    <IonSelectOption value={"email"}>{lang?.l.main.automation_action_send_email()}</IonSelectOption>
                    <IonSelectOption value={"notify"}>{lang?.l.main.automation_action_notify()}</IonSelectOption>
                    <IonSelectOption value={"input"}>Establecer Valor a Entrada</IonSelectOption>
                    <IonSelectOption value={"wait"}>Esperar Un Tiempo</IonSelectOption>
                </IonSelect>
            </IonItem>
            {this.state.action.action === 'output' && this.renderActionOutput(this.state.action)}
            {this.state.action.action === 'email' && this.renderActionEmail(this.state.action)}
            {this.state.action.action === 'input' && this.renderActionInput(this.state.action)}
            {this.state.action.action === 'notify' && this.renderActionNotification(this.state.action)}
            {this.state.action.action === 'wait' && this.renderActionWait(this.state.action)}


            {this.state.actionIndex >= 0 && <IonItem button detail={false} onClick={this.handleRemoveAction} color={"danger"}>
                <IonLabel>
                    {lang?.l.main.remove()}
                </IonLabel>
            </IonItem>}
        </>

    }

    renderCustomOperationEditor() {
        const { lang } = this.props;
        let suggestion = this.state.condition!.left;
        this.evaluateExpresion(this.state.condition!.left);
        let list: Operand[] = [];
        if (suggestion) {
            if (this.operationEditor.current) {
                let input = this.operationEditor.current.querySelector("input");
                if (input) {
                    let s = input.selectionStart;
                    if (s !== null && s >= 0) {
                        let i1 = suggestion.indexOf(" ", s);
                        let word = suggestion;
                        if (i1 >= 0) {
                            word = suggestion.substr(0, i1);
                        }
                        i1 = word.lastIndexOf(" ");
                        if (i1 > 0) {
                            word = word.substr(i1 + 1);
                        }
                        if (word.charAt(0) === "$") {
                            word = word.substr(1).toLowerCase();
                            list = this.values?.getOperands().filter(value => value.title.toLowerCase().indexOf(word) >= 0)!;
                        }
                        console.log(word, list);
                    }
                }
            }
        }
        return <>
            <IonItem>
                <IonInput labelPlacement="floating" label={lang?.l.main.automation_condition_title()} ref={this.operationEditor} onIonInput={(ev: any) => this.setState({ condition: { ...this.state.condition!, left: ev.detail.value } })} value={this.state.condition!.left}></IonInput>

            </IonItem>
            {list && list.map(value => {
                let variable = "${" + value.name + "}";
                return <IonItem onClick={this.handleReplaceVariable(variable)}>
                    <IonLabel>{value.title}</IonLabel>
                    <IonText>{variable} = {value.value}</IonText>
                </IonItem>
            })}
        </>
    }

    renderConditionEditor() {
        const { lang } = this.props;
        if (!this.state.condition) return "";
        let left = this.state.condition.left;
        let type = "decimal";
        let variable;
        let result = left.match(/\$\{([a-zA-Z0-9[\]]+)\}/)
        if (result) {
            variable = this.values?.getOperandByName(result[1]);
            type = (variable ? variable.type : "decimal");
        }
        let preview, error: any;
        try {
            preview = this.values!.toString(this.state.condition);
        } catch (e) {
            error = e;
        }

        return <>
            {(this.state.automation.conditions.length > 0 && this.state.conditionIndex !== 0) &&
                <IonItem>
                    <IonSelect label={lang?.l.main.automation_boolean()} labelPlacement="floating" interface={"popover"} value={this.state.condition.connector} onIonChange={(ev: any) => this.setState({ condition: { ...this.state.condition!, connector: ev.detail.value } })}>
                        <IonSelectOption value={ConditionConnector.AND}>{lang?.l.main.and()} </IonSelectOption>
                        <IonSelectOption value={ConditionConnector.OR}>{lang?.l.main.or()} </IonSelectOption>
                        {/* <IonSelectOption value={ConditionConnector.GROUP_START}> ( </IonSelectOption>
                        <IonSelectOption value={ConditionConnector.GROUP_END}> ) </IonSelectOption> */}
                    </IonSelect>
                </IonItem>}
            <IonItem>
                <IonSelect label={lang?.l.main.automation_if()} labelPlacement="floating" interface={"action-sheet"} value={this.state.condition.left} onIonChange={(ev: any) => this.setState({ condition: { ...this.state.condition!, left: ev.detail.value } })}>
                    {this.values && this.values.getOperands().map((value, index) => {
                        if (value.type === "separator") {
                            return <IonSelectOption disabled={true}>{ value.title }</IonSelectOption>
                        }
                        return <IonSelectOption key={index} value={"${" + value.name + "}"}>| - {value.title} ({value.value})</IonSelectOption>
                    })}

                    <IonSelectOption value={"$"}>{lang?.l.main.automation_operation()}</IonSelectOption>
                </IonSelect>
            </IonItem>
            {this.renderCustomOperationEditor()}
            <IonItem>
                <IonSelect label={lang?.l.main.automation_is()} labelPlacement="floating" interface={"popover"} value={this.state.condition.op} onIonChange={(ev: any) => this.setState({ condition: { ...this.state.condition!, op: ev.detail.value } })}>
                    {ConditionsValues.operators.map((value, index) => {
                        if (ConditionsValues.supported(value, type)) {
                            return <IonSelectOption key={index} value={value.name}>{value.title} {value.name}</IonSelectOption>
                        }
                        return "";
                    })}
                </IonSelect>
            </IonItem>
            <IonItem>
                {type === "decimal" && <IonInput label={lang?.l.main.automation_to_value()} labelPlacement="floating" value={this.state.condition.right} onIonInput={(ev) => this.setState({ condition: { ...this.state.condition!, right: String(ev.detail.value) } })}></IonInput>}
                {type === "boolean" && <IonSelect label={lang?.l.main.automation_to_value()} labelPlacement="floating" interface={"popover"} value={this.state.condition.right} onIonChange={(ev: any) => this.setState({ condition: { ...this.state.condition!, right: ev.detail.value } })}>
                    <IonSelectOption value={"1.00"}>{lang?.l.main.automation_on()}</IonSelectOption>
                    <IonSelectOption value={"0.00"}>{lang?.l.main.automation_off()}</IonSelectOption>
                </IonSelect>}
                {type === "select" && <IonSelect label={lang?.l.main.automation_to_value()} labelPlacement="floating" interface={"popover"} value={this.state.condition.right} onIonChange={(ev: any) => this.setState({ condition: { ...this.state.condition!, right: ev.detail.value } })}>
                    <IonSelectOption value={"1.00"}>{lang?.l.main.automation_on()}</IonSelectOption>
                    <IonSelectOption value={"0.00"}>{lang?.l.main.automation_off()}</IonSelectOption>
                </IonSelect>}
            </IonItem>

            {preview && <IonItem>
                <IonText>
                    {lang?.l.main.automation_if_short()} <IonBadge color={"secondary"}>{preview.left}</IonBadge> {lang?.l.main.automation_is()} <IonBadge color={"secondary"}>{preview.op}</IonBadge> {lang?.l.main.automation_to_short()} <IonBadge color={"secondary"}>{preview.right}</IonBadge>

                </IonText>
                <IonIcon slot={"end"} color={preview.result ? "success" : "danger"} icon={preview.result ? checkmarkOutline : closeOutline}></IonIcon>
            </IonItem>}
            {preview && <IonItem>
                <IonText>
                <IonBadge color={"secondary"}>{preview.left}</IonBadge> = {preview.stack}
                </IonText>
                
            </IonItem>}
            {error && <IonItem color={"warning"}>
                <IonLabel>{lang?.l.main.error()}</IonLabel>
                <IonText>
                    {error.toString()}
                </IonText>
            </IonItem>}
            {this.state.conditionIndex >= 0 && <IonItem button onClick={this.handleRemoveCondition} color={"danger"}>
                <IonLabel>
                    {lang?.l.main.remove()}
                </IonLabel>
            </IonItem>}
        </>
    }

    renderConnector(connector: ConditionConnector) {
        const { lang } = this.props;
        if (connector === ConditionConnector.AND) {
            return lang?.l.main.and();
        }
        if (connector === ConditionConnector.GROUP_START) {
            return "(";
        }
        if (connector === ConditionConnector.GROUP_END) {
            return ")";
        }
        if (connector === ConditionConnector.OR) {
            return lang?.l.main.or();
        }
        return "?";
    }

    renderEditor() {
        if (!this.state.automation) return "";
        let { lang } = this.props;
        let conds = this.state.automation.conditions;
        let actions = this.state.automation.actions;
        return <>
            <IonItem>
                <IonToggle checked={this.state.automation?.enabled} onIonChange={this.handleCheckChange('enabled')}
                    color="primary">
                    {lang?.l.main.automation_enable()}
                </IonToggle>
            </IonItem>
            <IonItem>
                <IonInput labelPlacement="stacked" label={lang?.l.main.title()} onIonInput={this.handleChange('title')} value={this.state.automation.title}></IonInput>
            </IonItem>
            <IonItem lines="none">
                <IonSelect label="Cuando ejecutar" labelPlacement="stacked" interface={"popover"} value={this.state.automation.exec} onIonChange={this.handleChange('exec')}>
                    <IonSelectOption value={"update"}>Cada vez que se actualicen las entradas</IonSelectOption>
                    <IonSelectOption value={"rising"}>Flanco de subida</IonSelectOption>
                    <IonSelectOption value={"falling"}>Flanco de bajada</IonSelectOption>
                    <IonSelectOption value={"change"}>Cambio de flanco</IonSelectOption>
                </IonSelect>
            </IonItem>
            {this.state.automation.exec === "update" && <IonItem ><IonText>Si se cumplen todas las condiciones se ejecutan las acciones. Podrá volver a ejecutarse cuando dejen de cumplirse las condiciones.</IonText></IonItem>}
            {this.state.automation.exec === "rising" && <IonItem ><IonText>Se ejecuta cuando las condiciones cambian de FALSE a TRUE</IonText></IonItem>}
            {this.state.automation.exec === "falling" && <IonItem ><IonText>Se ejecuta cuando las condiciones cambian de TRUE a FALSE</IonText></IonItem>}
            {this.state.automation.exec === "change" && <IonItem ><IonText>Se ejecuta cuando las condiciones cambian de FALSE a TRUE o de TRUE a FALSE</IonText></IonItem>}
            <IonItem lines="none"><IonText>Valor Ultima Ejecucion :  { this.state.automation.exec_last ? "TRUE" : "FALSE" }</IonText></IonItem>
            <IonItem>
                <IonInput labelPlacement="floating" label={lang?.l.main.automation_wait_title()} value={this.state.automation.trigger_delay} type={"number"} onIonInput={this.handleChange('trigger_delay')}></IonInput>

            </IonItem>
            <IonItem>
                <IonInput labelPlacement="floating" label={lang?.l.main.automation_rearm()} value={this.state.automation.trigger_rearm} type={"number"} placeholder={"(0 = OFF)"} onIonInput={this.handleChange('trigger_rearm')}></IonInput>

            </IonItem>

            <IonItemDivider>
                <IonLabel>
                    Condiciones
                </IonLabel>
            </IonItemDivider>
            {conds.map((value, index) => {
                let leftName = this.values?.getOperandByName(value.left);
                let preview = null;
                try {
                    preview = this.values!.toString(value);
                } catch (e) {

                }
                // let opName = ConditionsValues.title(value.op);
                if (preview) {
                    return <IonItem detail button onClick={() => { this.editCondition(index) }}>
                        <IonText>
                            {index > 0 ? <IonBadge color={"secondary"}>{this.renderConnector(preview.connector)}</IonBadge> : false} {lang?.l.main.automation_if_short()} <IonBadge color={"secondary"}>{preview.left}</IonBadge> {lang?.l.main.automation_is()} <IonBadge color={"secondary"}>{preview.op}</IonBadge> {lang?.l.main.automation_to_short()} <IonBadge color={"secondary"}>{preview.right}</IonBadge>
                        </IonText>
                        <IonIcon slot={"end"} color={preview.result ? "success" : "danger"} icon={preview.result ? checkmarkOutline : closeOutline}></IonIcon>
                    </IonItem>
                }
                return <IonItem detail button onClick={() => { this.editCondition(index) }}>{leftName ? leftName.title : value.left}</IonItem>
            })}
            <IonItem button onClick={() => this.addCondition()}><IonLabel><IonIcon icon={add} /> {lang?.l.main.add()}</IonLabel></IonItem>



            <IonItemDivider>
                <IonLabel>
                    {lang?.l.main.automation_actions()}
                </IonLabel>
            </IonItemDivider>
            {actions.map((value, index) => {
                return <IonItem detail button onClick={() => { this.editAction(index) }}>
                    <IonLabel>
                        {value.action === 'output' && this.renderDisplayActionOutput(value)}
                        {value.action === 'email' && this.renderDisplayActionEmail(value)}
                        {value.action === 'notify' && this.renderDisplayActionNotify(value)}
                        {value.action === 'input' && this.renderDisplayActionInput(value)}
                        {value.action === 'wait' && this.renderDisplayActionWait(value)}
                    </IonLabel>
                </IonItem>
            })}
            <IonItem button onClick={() => this.addAction()}><IonLabel><IonIcon icon={add} /> {lang?.l.main.add()}</IonLabel></IonItem>
            <IonItem>
                <IonTextarea labelPlacement="floating" label={lang?.l.main.notes()} onIonChange={this.handleChange('description')} value={this.state.automation.description}></IonTextarea>
            </IonItem>

            {this.state.automation.id > 0 && <IonItem color={"danger"} button detail={false} onClick={this.handleRemoveAutomation}><IonLabel><IonIcon icon={trash} /> {lang?.l.main.remove()}</IonLabel></IonItem>}
        </>;
    }
    renderItem = (value: Automation, index: number) => {
        const { lang } = this.props;
        return <IonItem key={index} button detail onClick={() => { this.editAutomation(value) }}>
            <IonLabel>{value.title}</IonLabel>
            <IonBadge slot={"end"} color={"danger"} hidden={!value.triggered}>{lang?.l.main.automation_executed()}</IonBadge>
            <IonBadge slot={"end"} color={value.enabled ? "success" : "danger"}>{value.enabled ? lang?.l.main.automation_enabled() : lang?.l.main.automation_disabled()}</IonBadge>

        </IonItem>
    }

    renderList() {
        return this.state.automationList.map(this.renderItem);
    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state) return "";
        const { lang } = this.props;

        return <>
            <IonList>
                <IonItemDivider>
                    <IonLabel>{lang?.l.main.automation_automations()}</IonLabel>
                </IonItemDivider>
                {this.renderList()}
            </IonList>
            <IonModal ref={this.automationModal} isOpen={this.state.edit ? true : false} onDidDismiss={() => this.setState({edit: false})} >

                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{this.state.automation && this.state.automation.id > 0 ? lang?.l.main.edit() : lang?.l.main.add()} {lang?.l.main.automation_automation()}</IonTitle>

                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ edit: false })}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>

                            <IonButton onClick={this.handleEditAutomation}><IonSpinner
                                hidden={!this.state.loading} /> {lang?.l.main.save()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>


            <IonModal presentingElement={this.automationModal.current!} isOpen={this.state.editCond ? true : false} onDidDismiss={() => this.setState({ editCond: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.automation_edit_condition()}</IonTitle>
                        <IonButtons slot={"secondary"}>

                            <IonButton onClick={() => this.setState({ editCond: false })}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleEditCondition}>{lang?.l.main.save()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderConditionEditor()}
                </IonContent>

            </IonModal>

            <IonModal presentingElement={this.automationModal.current!} isOpen={this.state.editAction ? true : false} onDidDismiss={() => this.setState({ editAction: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.automation_edit_action()}</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ editAction: false })}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleEditAction}>{lang?.l.main.save()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderActionEditor()}
                </IonContent>

            </IonModal>
        </>;
    }
}
