import React from "react";
import {ModbusType} from "../service/API";
import {
    IonAlert,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {trashBinOutline} from "ionicons/icons";
import { Format } from "../service/Formats";

type ModbusEditProps = {
    modbus?: ModbusType,
    onSave?: (modbus?:ModbusType) => void,
    onTest?: (modbus?:ModbusType) => Promise<any>,
    type: 'input' | 'output',
    routerOutlet:HTMLElement;
}
type ModbusEditState = {
    edit: boolean,
    loading?: boolean,
    modbus?: ModbusType,
    message?:string,
    test?:string,
}
export default class ModbusEdit extends React.Component<ModbusEditProps, ModbusEditState> {
    state:ModbusEditState = {
        edit: false,
    }

    componentDidUpdate(prevProps: Readonly<ModbusEditProps>, prevState: Readonly<ModbusEditState>, snapshot?: any) {
        // if(this.props.modbus && (!this.state.modbus || this.props.modbus.id !== this.state.modbus.id)) {
        //
        // } else if(!this.props.modbus && (this.state.modbus && this.state.modbus.id !== 0)) {
        //
        // }
    }

    isModbusValid(modbus:ModbusType) {
        if(modbus.station! >= 0 && modbus.station! <= 247) {
            if(modbus.functionCode > 0 && modbus.functionCode < 0xFF) {
                return true;
            } else {
                return new Error('Código de función no válido. (0-255)');
            }
        } else {
            return new Error('Número de estación no valido.');
        }
    }

    canProbar() {
        return this.props.type === 'input' && this.props.onTest;
    }

    handleChange = (prop:string) => (ev:CustomEvent<any>) => {
        this.setState({modbus: {...this.state.modbus!, [prop]: ev.detail.value}});
    }

    handleSave = (ev:any) => {
        this.setState({edit: false});
        this.props.onSave!(this.state.modbus);
    }

    handleRemove = (ev:any) => {
        this.setState({edit: false});
        this.props.onSave!(undefined);
    }
    handleEdit = (ev:any) => {
        if(!this.props.modbus) {
            this.setState({modbus: {id: 0, host: '', port: 502, protocol: 0, address: 0, dataFormat: 0, functionCode: 0, name: "", registers: 1, station: 1, mappers: [], retries: 0}});
        } else {
            this.setState({modbus: this.props.modbus});
        }
        this.setState({edit: true});
    }

    handleTest = (ev:any) => {
        if(this.props.type === 'input') {
            if(this.props.onTest) {
                let res = this.isModbusValid(this.state.modbus!);
                if(res === true) {
                    this.props.onTest(this.state.modbus).then(value => {
                        this.setState({test: value})
                    }).catch(reason => {
                        this.setState({message: 'Se ha producido un error al probar la función: ' + reason});
                    });
                } else if(res instanceof Error) {
                    this.setState({message: res.message});
                }
            } else {

                this.setState({message: 'No hay un método para probar la función.'});
            }

        } else {
            this.setState({message: 'Solo se permite probar funciones de lectura'});
        }
    }

    renderInput() {
        return "Entrada"

    }

    renderOutput() {

        return "Salida"
    }

    renderFunctionCodes() {
        if(this.props.type === 'input') {
            return <>
                <IonSelectOption value={1}>0x01 - ReadHoldingCoil</IonSelectOption>
                <IonSelectOption value={2}>0x02 - ReadDiscreteInputs</IonSelectOption>
                <IonSelectOption value={3}>0x03 - ReadHoldingRegister</IonSelectOption>
                <IonSelectOption value={4}>0x04 - ReadInputRegisters</IonSelectOption>
            </>
        }
        if(this.props.type === 'output') {
            return <>
                <IonSelectOption value={5}>0x05 - WriteSingleCoil</IonSelectOption>
                <IonSelectOption value={6}>0x06 - WriteHoldingRegister</IonSelectOption>
                <IonSelectOption value={0x0F}>0x0F - WriteMultipleCoil</IonSelectOption>
                <IonSelectOption value={0x10}>0x10 - WriteMultipleRegisters</IonSelectOption>
            </>
        }
    }

    renderModal() {

        const { modbus } = this.state;
        if(!modbus) return;
        return <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.edit! ? true: false}
                  onDidDismiss={() => this.setState({edit: false})}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Editar Función Modbus</IonTitle>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={this.handleSave}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonList>
                    <IonItem>
                        <IonInput label="Título" labelPlacement="floating" value={modbus.name} onIonInput={this.handleChange('name')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Protocolo" labelPlacement="floating" value={modbus.protocol} placeholder="Seleccionar Protocolo" onIonChange={this.handleChange('protocol')}>
                            
                            <IonSelectOption value={0}>Modbus RTU</IonSelectOption>
                            <IonSelectOption value={1}>Modbus TCP</IonSelectOption>
                            <IonSelectOption value={2}>MQTT</IonSelectOption>

                        </IonSelect>
                    </IonItem>
                    {modbus.protocol === 2 && 
                    <IonItem>
                        <IonInput label="IPv4/IPv6/Host:" value={modbus.host} type={"text"} max={"247"} step={"1"}  onIonInput={this.handleChange('host')}></IonInput>
                        
                        <IonInput label="Port: " value={modbus.port} type={"number"} max={"65535"} step={"1"}  onIonInput={this.handleChange('port')}></IonInput>
                   
                    </IonItem>}
                    <IonItem>
                        <IonInput labelPlacement="floating" label="Estación (0 - 247)" value={modbus.station} type={"number"} max={"247"} step={"1"}  onIonInput={this.handleChange('station')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="FC" labelPlacement="floating" value={modbus.functionCode} placeholder="Seleccionar Código de Función" onIonChange={this.handleChange('functionCode')}>
                            {this.renderFunctionCodes()}

                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonInput labelPlacement="floating" label="Dirección" value={modbus.address} type={"number"} onIonInput={this.handleChange('address')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput labelPlacement="floating" label="Número de Registros" value={modbus.registers} type={"number"} max={"100"} step={"1"} onIonInput={this.handleChange('registers')}></IonInput>
                    </IonItem>
                    <IonItem>                        
                        <IonSelect label="Formato" labelPlacement="floating" interface="action-sheet" value={modbus.dataFormat} placeholder="Seleccionar el formato de los datos" onIonChange={this.handleChange('dataFormat')} >
                            {Object.keys(Format).filter(key => !isNaN(Number(key))).map((element) => {
                                    return <IonSelectOption value={parseInt(element)}>{Format[Number(element)]}</IonSelectOption>
                                })}
                        </IonSelect>
                    </IonItem>
                    <IonItem detail={true} detailIcon={trashBinOutline} button color={"danger"} onClick={this.handleRemove}>
                        <IonLabel>Quitar</IonLabel>
                    </IonItem>
                    {this.canProbar() && <IonItem button color={"secondary"} onClick={this.handleTest}>
                        <IonLabel>Probar</IonLabel>
                    </IonItem>}

                    {this.state.test && <IonItem>
                        <IonLabel>Resultado de la prueba: {this.state.test}</IonLabel>
                    </IonItem>}
                </IonList>
                {/*{this.props.type === 'input' && this.renderInput()}*/}
                {/*{this.props.type === 'output' && this.renderOutput()}*/}

            </IonContent>

        </IonModal>
    }

    render() {
        return <>
            <IonItem button detail onClick={this.handleEdit}>
                <IonLabel>Función MODBUS de {this.props.type === 'input' ? "lectura":"escritura"}: {this.props.modbus ? this.props.modbus.name : "(Crear nueva)"}</IonLabel>
            </IonItem>
            {this.renderModal()}
            <IonAlert
                isOpen={!!this.state.message}
                onDidDismiss={() => this.setState({message: undefined})}
                cssClass='my-custom-class'
                header={'Advertencia'}
                message={this.state.message}
                buttons={['OK']}
            />
        </>;
    }

}
