import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { match } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Permission } from "../../service/API";
import { add, checkmarkOutline, mail, trash } from "ionicons/icons";
import Utils from "../../service/Utils";
import moment from "moment";
import DeviceSettingsNotifications from "./DeviceSettingsNotifications";


export type DeviceSettingsShareProps = {
    match?: match<{ id: string }>,
    store?: Store,
}
export type DeviceSettingsShareState = {
    loading?: boolean,
    permissions?: Permission[],
    edit?: boolean,
    loadingEdit?: boolean,
    loadingResend?: boolean,
    resent?: boolean,
    permission: Permission,
    contactPhoneEmail: string,
    showLink?: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsShare extends React.Component<DeviceSettingsShareProps, DeviceSettingsShareState> {
    permissions: { [key: string]: number } = {
        // OWNER: 0b10000000,
        CONTROL_PERMISSIONS: 0b00100000, // Modificar permisos
        CONTROL_SERVER: 0b00010000, // Modificar configuración servidor
        CONTROL_DEVICE: 0b00001000, // Modificar configuración cliente
        CONTROL_SCHEDULER: 0b00000100, // Controlar programadores
        CONTROL_EVENTS: 0b00000010, // Controlar eventos
        CONTROL_OUTPUTS: 0b00000001, // Controlar salidas

        READONLY: 0b00000000
    };

    names: { [key: string]: string } = {
        CONTROL_PERMISSIONS: 'Modificar permisos',
        CONTROL_SERVER: 'Modificar nombre dispositivo, nombre de entradas/salidas, valores máximos y mínimos ',
        CONTROL_DEVICE: 'Modificar configuración cliente',
        CONTROL_SCHEDULER: 'Modificar planificadores',
        CONTROL_EVENTS: 'Modificar automatismos',
        CONTROL_OUTPUTS: 'Controlar salidas',
    }
    short_names: { [key: string]: string } = {
        CONTROL_PERMISSIONS: 'Permisos',
        CONTROL_SERVER: 'Servidor',
        CONTROL_DEVICE: 'Configurar',
        CONTROL_SCHEDULER: 'Planificadores',
        CONTROL_EVENTS: 'Automatismos',
        CONTROL_OUTPUTS: 'Salidas',
    }

    notifications = DeviceSettingsNotifications.NOTIFICATIONS;

    componentDidMount(): void {
        this.loadPermissions();
        this.props.store?.events.on('permissions', () => {
            this.loadPermissions();
        })
    };

    componentDidUpdate(prevProps: Readonly<DeviceSettingsShareProps>, prevState: Readonly<DeviceSettingsShareState>, snapshot?: any): void {
        if (this.props.match!.params.id !== prevProps.match!.params.id) {
            this.loadPermissions();
        }
    }

    componentWillUnmount(): void {
        this.props.store?.events.off('permissions');
    }

    loadPermissions() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        this.props.store?.api.permissionsGet(Number(id)).then(value => {
            if (value.success) {
                this.setState({ permissions: value.data })
            }
        }).finally(() => this.setState({ loading: false }));

    }

    createPermission(): Permission {
        return { id: 0, created_at: new Date(), updated_at: new Date(), permissions: 0, user_id: 0, flag: 0 };
    };

    handleAdd = (ev: any) => {
        this.setState({ edit: true, permission: this.createPermission() });
    };

    handleEdit = (permission: Permission) => (ev: any) => {
        this.setState({ edit: true, permission: permission });
    };


    handleSetPermission = (flag: number) => (ev: any) => {
        let permissions = Utils.setFlag(this.state.permission.permissions, flag, ev.currentTarget.checked);
        this.setState({ permission: { ...this.state.permission, permissions: permissions } });
    }
    handleSetFlag = (flag: number) => (ev: any) => {
        let flags = Utils.setFlag(this.state.permission.flag, flag, ev.currentTarget.checked);
        this.setState({ permission: { ...this.state.permission, flag: flags } });
    }
    isAllowed(flag: number, flags: number) {

        return Utils.haveFlag(flags, flag);
    }

    handleSave = (ev: any) => {
        let id = this.props.match?.params.id!;
        if (this.state.permission.id === 0) {
            this.setState({ loadingEdit: true });
            this.props.store?.api.permissionsAdd(Number(id), this.state.contactPhoneEmail, this.state.permission).then(result => {
                this.setState({ loadingEdit: false });
                if (result.success) {
                    this.setState({ edit: false });
                    this.loadPermissions();
                }
            })
        } else {
            // Update permissions
            this.setState({ loadingEdit: true });

            this.props.store?.api.permissionsUpdate(Number(id), this.state.permission).then((result) => {
                this.setState({ loadingEdit: false });

                if (result.success) {

                    this.setState({ edit: false });
                    this.loadPermissions();
                }
            })
        }
        // const {name, flags} = this.state.device!;
        // this.props.store?.api.devicePost(this.state.device!.id, {name, flags}).finally(() => this.setState({loading: false}));
    };
    handleRemove = (ev: any) => {
        this.setState({ loadingEdit: true });
        let id = this.props.match?.params.id!;
        if (this.state.permission.id !== 0) {
            this.props.store?.api.permissionsDelete(Number(id), this.state.permission.id).then(value => {
                this.setState({ loadingEdit: false });
                if (value.success) {

                    this.setState({ edit: false });
                    this.loadPermissions();
                }
            });
        }
    }
    handleResend = (ev: any) => {
        this.setState({ loadingResend: true });
        let id = this.props.match?.params.id!;
        if (this.state.permission.id !== 0) {
            this.props.store?.api.permissionsResend(Number(id), this.state.permission.id).then(value => {
                this.setState({ loadingResend: false });
                if (value.success) {
                    this.setState({ resent: true });
                    this.loadPermissions();
                }
            });
        }

    }

    handleAddContact = (ev: any) => {

    }

    renderPermissionEdit() {
        if (this.state.permission.invitation)

            return <>
                <IonItem>
                    <IonInput label="Usuario" labelPlacement="stacked" value={this.state.permission.contact} onIonInput={(ev: any) => this.setState({ permission: { ...this.state.permission, contact: ev.currentTarget.value } })}></IonInput>
                </IonItem>
                <IonItem hidden={!!this.state.permission.user_id}>
                    {/* eslint-disable-next-line no-restricted-globals */}
                    <IonInput label="Link de Invitación" hidden={!this.state.showLink} value={"https://" + location.host + "/invitation/" + this.state.permission.invitation?.token} readonly></IonInput>
                    <IonButton slot={"end"} fill={"clear"} onClick={() => this.setState({ showLink: !this.state.showLink })}>Mostrar</IonButton>
                </IonItem>
                <IonItem color={"primary"} button detail={false} onClick={this.handleResend} hidden={!!this.state.permission.user_id}>
                    <IonLabel> Volver a enviar Invitación</IonLabel>
                    {this.state.resent && <IonIcon slot={"end"} icon={checkmarkOutline} />}
                    {this.state.loadingResend && <IonSpinner />}
                    {!this.state.resent && !this.state.loadingResend && <IonIcon slot={"end"} icon={mail} />}

                </IonItem>
            </>
    }
    renderPermissionAdd() {
        return <>
            <IonListHeader>
                <IonLabel>
                    Añadir nuevo usuario para que tenga acceso a este dispositivo. Si el usuario no tiene cuenta, se
                    le enviará un link con la invitación al dispositivo.</IonLabel>
            </IonListHeader>
            <IonItem>
                <IonInput label="Email o número de teléfono" labelPlacement="stacked" onIonChange={(ev: any) => this.setState({ contactPhoneEmail: ev.currentTarget.value })}></IonInput>
                {/*<IonButton onClick={this.handleAddContact}><IonIcon icon={personAddOutline} /></IonButton>*/}
            </IonItem>
        </>
    }

    renderPermissions() {

        if (!this.state.permission) return "";
        return <>
            {Object.keys(this.names).reverse().map(key => {
                let flag = this.permissions[key];
                return <IonItem>
                    <IonToggle checked={this.isAllowed(flag, this.state.permission.permissions)} onIonChange={this.handleSetPermission(flag)}>
                        {this.names[key]}
                    </IonToggle>
                </IonItem>
            })}
        </>

    }
    renderNotifications() {

        if (!this.state.permission) return "";
        let allowed = this.isAllowed(1, this.state.permission.flag);
        return <>
            <IonItem>
                <IonToggle checked={allowed} onIonChange={this.handleSetFlag(1)}>
                    Establecer notificaciones personalizadas
                </IonToggle>
            </IonItem>
            {(this.notifications).map((value, key) => {
                return <IonItem disabled={!allowed}>
                    <IonToggle checked={this.isAllowed(value.flag, this.state.permission.flag)} onIonChange={this.handleSetFlag(value.flag)}>
                        {value.name}
                    </IonToggle>
                </IonItem>
            })}
        </>

    }

    renderListItem(value: Permission) {
        return <>
            <IonText>{value.contact}</IonText>
            <IonBadge color="warning" slot={"end"} hidden={value.invitation === undefined || !!value.user_id}>Invitación Pendiente</IonBadge>
            <IonBadge color="primary" slot={"end"}>{moment(value.created_at).format("YYYY-MM-DD HH:mm:ss")}</IonBadge>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Compartir</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>

                    <IonListHeader>
                        <IonLabel>Usuarios compartidos</IonLabel>
                    </IonListHeader>
                    {this.state.permissions && this.state.permissions.map((value, index) => {
                        return <IonItem button detail onClick={this.handleEdit(value)}>
                            {this.renderListItem(value)}
                        </IonItem>
                    })}
                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />

                <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={this.handleAdd}>
                    <IonFabButton>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
                <IonModal isOpen={this.state.edit! ? true : false} onDidDismiss={() => this.setState({ edit: false })}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Compartir dispositivo</IonTitle>
                            <IonButtons slot={"secondary"}>
                                <IonButton onClick={() => this.setState({ edit: false })}>Cancelar</IonButton>
                            </IonButtons>
                            <IonButtons slot={"primary"}>
                                <IonButton onClick={this.handleSave}><IonSpinner hidden={!this.state.loadingEdit} /> Guardar</IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <IonContent>
                        {this.state && this.state.permission && this.state.permission.id === 0 && this.renderPermissionAdd()}
                        {this.state && this.state.permission && this.state.permission.id > 0 && this.renderPermissionEdit()}
                        <IonItem>
                            <IonLabel>Este usuario tiene acceso a las siguientes características</IonLabel>
                        </IonItem>
                        {this.renderPermissions()}

                        <IonItemDivider>
                            <IonLabel>
                                Notificaciones
                            </IonLabel>
                        </IonItemDivider>
                        {this.renderNotifications()}


                        {this.state && this.state.permission && this.state.permission.id > 0 && <IonItem color={"danger"} button detail={false} onClick={this.handleRemove}>
                            <IonLabel>Eliminar</IonLabel>
                            <IonIcon slot={"end"} icon={trash} />

                        </IonItem>}
                    </IonContent>

                </IonModal>
            </IonContent>
        </IonPage>
    }

}
